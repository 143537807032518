import { create } from "zustand";
import { createJSONStorage, devtools, persist } from "zustand/middleware";
import { getCustomerData, getEventAnalytics } from "../api/analytics";
import {
  formatLineChartData,
  getMonthStartEnd,
} from "core/helpers/generalHelpers";

type State = {
  isLoading: boolean;
  customerDataList: CustomerDataList;
  customerData: CustomerData[];
  eventGraphData: EventGraphData;
  analyticsQuery: EventAnalyticsQuery;
  eventAnalytics: EventAnalyticData;
};

type Action = {
  getCustomerDataList: (query: UserDataQueryParam) => Promise<void>;
  getEventAnalytics: (query: EventAnalyticsQuery) => Promise<void>;
  reset: () => void;
};

type AnalyticsState = State & Action;

const monthStartAndEnd = getMonthStartEnd();

const initialState: State = {
  isLoading: false,
  analyticsQuery: {
    locationId: "",
    startDate: monthStartAndEnd.start,
    endDate: monthStartAndEnd.end,
    event: 0,
  },
  eventGraphData: {
    date: [],
    female: [],
    male: [],
  },
  customerDataList: {
    data: [],
    pageNumber: 1,
    pageSize: 20,
    totalCount: 0,
    totalPage: 1,
  },
  eventAnalytics: {
    estimatedTargetUsers: [],
    eventAnalytics: [],
  },
  customerData: [],
};

const useAnalyticStore = create<AnalyticsState>()(
  devtools(
    persist(
      (set, get): AnalyticsState => ({
        ...initialState,
        getCustomerDataList: async (query) => {
          set({ isLoading: true });
          var res: UIResponse = await getCustomerData(query);

          if (res?.isSuccessful) {
            set({ customerData: res?.data?.data });
          }

          set({ isLoading: false });
          return;
        },
        getEventAnalytics: async (query) => {
          set({ isLoading: true, analyticsQuery: query });
          var res: UIResponse = await getEventAnalytics(query);

          if (res?.isSuccessful) {
            set({
              eventAnalytics: res?.data?.data,
              eventGraphData: formatLineChartData(res?.data?.data),
            });
          } else {
            set({
              eventAnalytics: { estimatedTargetUsers: [], eventAnalytics: [] },
              eventGraphData: formatLineChartData(null),
            });
          }

          set({ isLoading: false });
          return;
        },
        reset: () => {
          set({
            ...initialState,
          });
        },
      }),
      {
        name: "analyticStore",
        storage: createJSONStorage(() => sessionStorage),
      },
    ),
  ),
);

export default useAnalyticStore;
