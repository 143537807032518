export const USER_ROLES = {
  super_admin: "super_admin",
  accommodation: "accommodation",
  marketplace: "marketplace",
  laundry: "laundry",
  foodstuff: "foodstuff",
  engineering: "engineering",
  transportation: "transportation",
};

export const USER_STATUS = {
  suspended: "suspended",
  pending: "pending",
  approved: "approved",
};

export const ADVERT_TYPES = {
  default: "default",
  main: "main",
};

export const ADMIN_ACTION = {
  approve: "approve",
  activate: "activate",
  deactivate: "deactivate",
  remove: "remove",
};

export const BUSINESS_MODULES = [
  {
    name: "Accommodation",
    value: "accommodation",
  },
  {
    name: "Market Place",
    value: "marketplace",
  },
  {
    name: "Laundry",
    value: "laundry",
  },
  {
    name: "Food Stuff",
    value: "foodstuff",
  },
  {
    name: "Maintenance & Engineering Services",
    value: "engineering",
  },
  {
    name: "Transportation",
    value: "transportation",
  },
];

export const REWARD_TYPES = {
  referrerbonus: "referrerbonus",
  referreduserdiscount: "referreduserdiscount",
};

export const SECURITY_QUESTIONS = [
  "What is your mother's maiden name?",
  "What is your father's middle name?",
  "What is the name of your favorite pet?",
  "What is the name of your Primary School?",
  "What is the name of your first room mate?",
];

export const ID_TYPES = [
  {
    name: "National ID",
    value: "National ID",
  },
  {
    name: "Permanent Voters ID",
    value: "Permanent Voters ID",
  },
  {
    name: "Drivers license",
    value: "Drivers license",
  },
  {
    name: "International Passport",
    value: "International Passport",
  },
];

export const GENDERS = [
  { name: "Female", value: "Female" },
  { name: "Male", value: "Male" },
];

export const REGISTRATION_STATUS = [
  {
    name: "Approved",
    value: "Approved",
  },
  {
    name: "Pending",
    value: "Pending",
  },
  {
    name: "Deleted",
    value: "Deleted",
  },
  {
    name: "Suspended",
    value: "Suspended",
  },
];

export const LOAN_STATUS = [
  {
    name: "Pending",
    value: "Pending",
  },
  {
    name: "Active",
    value: "Active",
  },
  {
    name: "Declined",
    value: "Declined",
  },
  {
    name: "Closed",
    value: "Closed",
  },
];

export const EVENTS = [
  {
    name: "Sign Ups",
    value: 0,
  },
  {
    name: "Sign Ins",
    value: 1,
  },
];

export const LOAN_STATUS_LIST = {
  Pending: "Pending",
  Active: "Active",
  Declined: "Declined",
  Closed: "Closed",
};

export const REPAYMENT_STATUS = {
  Pending: "pending",
  Paid: "paid",
};

export const MARKETPLACE_CATEGORY = [
  {
    name: "Fast food",
    value: "fast_food",
  },
  {
    name: "Gas refill",
    value: "gas_refill",
  },
  {
    name: "Event ticket",
    value: "event_ticket",
  },
  {
    name: "Hotel",
    value: "hotel",
  },
  {
    name: "Media and Production",
    value: "media_production",
  },
];

export const MARKETPLACE_CATEGORY_TYPES = {
  Fast_Food: "fast_food",
  Gas_Refill: "gas_refill",
  Event_Ticket: "event_ticket",
  Media_Production: "media_production",
  Hotel: "hotel",
};

export const ORDER_STATUS = {
  pending: "Pending",
  processing: "Processing",
  cancelled: "Cancelled",
  delivered: "Delivered",
  accepted: "Accepted",
  successful: "Successful",
  failed: "Failed",
  awaiting_payment: "awaiting_payment",
};

export const ORDER_STATUS_LIST = Object.entries(ORDER_STATUS).map(
  ([name, value]) => ({
    name: name
      .replace(/_/g, " ")
      .replace(/(^[a-z]|\b[a-z])/g, (letter) => letter.toUpperCase()),
    value,
  }),
);

export const RIDE_STATUS = {
  /*
  UNASSIGNED: "Unassigned",
  ASSIGENED: "Assigned",
  INTRANSIT: "InTransit",
  COMPLETED: "Completed",
  CANCELLED: "Cancelled",
  */
  Initiated: 1,
  InTransit: 2,
  Completed: 3,
  Cancelled: 4,
};

export const RIDE_STATUS_LIST = Object.entries(RIDE_STATUS).map(
  ([name, value]) => ({
    name: name,
    value: name,
  }),
);

export const PROCESS_ORDER = {
  accept: "accept",
  cancel: "cancel",
  delivered: "delivered",
};

export const PROCESS_INSPECTION_REQUEST = {
  accepted: "accepted",
  fulfilled: "fulfilled",
  declined: "declined",
  pending: "pending",
};

export const TRANSPORTATION_AGENTS = [
  {
    name: "Delivery Agent",
    value: 1,
  },
  {
    name: "Driver",
    value: 4,
  },
];

export const TRANSPORTATION_AGENTS_TYPE = {
  deliveryAgent: 1,
  driver: 4,
};

export const NotificationType: any = {
  "1": "Cancelled",
  "2": "Delivered",
  "3": "Assigned",
  "4": "Shipped",
  "5": "Weight Update",
  "6": "Received",
  "0": "",
  "": "",
};

export const ADVERT_PLACEMENT = [
  {
    name: "Home",
    value: "Home",
  },
  {
    name: "Market Place",
    value: "Marketplace",
  },
];

export const ADVERT_PLACEMENT_TYPES = {
  Home: "Home",
  Marketplace: "Marketplace",
};

export const PAYMENT_OPTIONS = [
  {
    name: "Card",
    value: 1,
  },
  {
    name: "Bank Transfer",
    value: 2,
  },
  {
    name: "Wallet Transfer",
    value: 3,
  },
];
