import { apicall } from "./apicall";

export const getCustomerData = (query: UserDataQueryParam) => {
  return apicall({
    endpoint: "/auth/getcustomerdatacenter",
    method: "GET",
    pQuery: { ...query },
    auth: true,
  });
};

export const getEventAnalytics = (query: EventAnalyticsQuery) => {
  return apicall({
    endpoint: "/analytics/user/events",
    method: "GET",
    pQuery: { ...query },
    auth: true,
  });
};
