import {
  activeModule,
  home,
  locationImg,
  profileuser,
  profileuser2,
  settlement,
  userIcon,
  users,
} from "core/consts/images";
import ads from "assets/icons/ads.svg";
import ActiveModules from "./views/ActiveModules";
import AdminList from "./views/AdminList";
import Dashboard from "./views/Dashboard";
import DataCenter from "./views/DataCenter";
import LocationManagement from "./views/LocationManagement";
import Settings from "./views/Settings";
import SupportList from "./views/SupportList";
import TransactionSettlement from "./views/TransactionSettlement";
import ReferralSettings from "./views/ReferralProgram/ReferralSettings";
import AgencyReferral from "./views/ReferralProgram/AgencyReferral";
import AgencyReferree from "./views/ReferralProgram/AgencyReferree";
import CustomerReferral from "./views/ReferralProgram/CustomerReferral";
import CustomerReferree from "./views/ReferralProgram/CustomerReferree";
import AdManagement from "./views/AdManagement";
import SmsVendorManagement from "./views/SmsVendorManagement";
import LoanServiceManagement from "./views/LoanServiceManagement/LoanServiceManagement";
import loanLogo from "assets/icons/loans.svg";
import LoanSettings from "./views/LoanServiceManagement/LoanSettings";
import LoanParameters from "./views/LoanServiceManagement/LoanParameters";
import LoanDetails from "./views/LoanServiceManagement/LoanDetails";
import EngagementAnalytics from "./views/Engagements/EngagementAnalytics";

const routes: RoutesType[] = [
  {
    name: "Dashboard",
    layout: "/superadmin",
    path: "dashboard",
    icon: home,
    component: <Dashboard />,
    children: [],
  },
  {
    name: "Admin List",
    layout: "/superadmin",
    path: "admin-list",
    icon: userIcon,
    component: <AdminList />,
    children: [],
  },
  {
    name: "Active Modules",
    layout: "/superadmin",
    path: "active-modules",
    icon: activeModule,
    component: <ActiveModules />,
    children: [],
  },
  {
    name: "Transaction Settlement",
    layout: "/superadmin",
    path: "transaction-settlement",
    icon: settlement,
    component: <TransactionSettlement />,
    children: [],
  },
  {
    name: "Support List",
    layout: "/superadmin",
    path: "support-list",
    icon: profileuser,
    component: <SupportList />,
    children: [],
  },
  {
    name: "Location Management",
    layout: "/superadmin",
    path: "location-management",
    icon: locationImg,
    component: <LocationManagement />,
    children: [],
  },
  {
    name: "",
    layout: "/superadmin",
    path: "referral-agencies",
    icon: <></>,
    component: <AgencyReferral />,
    children: [],
  },
  {
    name: "",
    layout: "/superadmin",
    path: "referral-agencies/:referralCode",
    icon: <></>,
    component: <AgencyReferree />,
    children: [],
  },
  {
    name: "Referral Program",
    layout: "/superadmin",
    path: "referral-customers",
    icon: users,
    component: <CustomerReferral />,
    children: [],
  },
  {
    name: "",
    layout: "/superadmin",
    path: "referral-customers/:referrerId",
    icon: <></>,
    component: <CustomerReferree />,
    children: [],
  },
  {
    name: "Referral Settings",
    layout: "/superadmin",
    path: "referral-settings",
    component: <ReferralSettings />,
    icon: profileuser2,
    children: [],
  },
  {
    name: "Loan Management",
    layout: "/superadmin",
    path: "loan-management",
    component: <LoanServiceManagement />,
    icon: loanLogo,
    children: [],
  },
  {
    name: "",
    layout: "/superadmin",
    path: "loan-management/requests/:requestId",
    icon: <></>,
    component: <LoanDetails />,
    children: [],
  },
  {
    name: "",
    layout: "/superadmin",
    path: "loan-management/loan-parameter",
    component: <LoanParameters />,
    icon: <></>,
    children: [],
  },
  {
    name: "",
    layout: "/superadmin",
    path: "loan-management/settings",
    component: <LoanSettings />,
    icon: <></>,
    children: [],
  },
  {
    name: "Settings",
    layout: "/superadmin",
    path: "settings",
    icon: profileuser2,
    component: <Settings />,
    children: [],
  },
  {
    name: "SMS Vendor Management",
    layout: "/superadmin",
    path: "sms-vendor-management",
    icon: settlement,
    component: <SmsVendorManagement />,
    children: [],
  },
  {
    name: "Data Center",
    layout: "/superadmin",
    path: "data-center",
    icon: users,
    component: <DataCenter />,
    children: [],
  },
  {
    name: "Ad Management",
    layout: "/superadmin",
    path: "ad-management",
    icon: ads,
    component: <AdManagement />,
    children: [],
  },
  {
    name: "Engagement & Analytics",
    layout: "/superadmin",
    path: "analytics",
    icon: users,
    component: <EngagementAnalytics />,
    children: [],
  },
];

export default routes;
